import React from 'react';
import { Typography, Button, Card, CardContent } from '@mui/material';
import { ChevronRight, Award, Users, Globe, Heart, GraduationCap } from 'lucide-react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';


const About = () => {
  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
       <Helmet>
        <title>About GS IFS - School for Deaf Education</title>
        <meta name="description" content="Learn more about GS IFS, an inclusive school for deaf students, promoting educational excellence." />
        <meta name="keywords" content="GS IFS, deaf education, best school Rwanda, inclusive education, deaf sisters school" />
      </Helmet>
      <Navbar />

      <main className="flex-grow">
        <section className="bg-blue-800 text-white py-20">
          <div className="container mx-auto text-center">
            <h1 className="text-4xl md:text-6xl font-bold mb-4">About GS INSTITUT FILIPPO SMALDONE</h1>
            <p className="text-xl mb-8">Empowering Deaf and Hearing Students in Kigali, Rwanda</p>
          </div>
        </section>

        <section className="py-16">
          <div className="container mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
              <div>
                <h2 className="text-3xl font-bold mb-4 text-blue-900">Our Mission</h2>
                <p className="text-lg mb-6 text-gray-700">
                  At GS INSTITUT FILIPPO SMALDONE, we are committed to providing inclusive education 
                  for both deaf and hearing students. Located in Kigali, Nyamirambo, Rwanda, we strive 
                  to create a nurturing environment where all students can explore their potential, 
                  develop critical thinking skills, and prepare for the challenges of the future.
                </p>
                <p className="text-lg mb-6 text-gray-700">
                  As a Catholic institution owned by The Sisters of the Sacred Hearts and a government-aided 
                  school, we blend spiritual values with academic excellence, ensuring our students receive 
                  a well-rounded education.
                </p>
                <Button 
                  variant="contained" 
                  sx={{ 
                    bgcolor: '#eab308', 
                    '&:hover': { bgcolor: '#ca8a04' }, 
                    color: '#1e3a8a'
                  }}
                >
                  Learn More <ChevronRight className="ml-2" />
                </Button>
              </div>
              <div className="bg-yellow-400 h-64 rounded-lg shadow-lg"></div>
            </div>
          </div>
        </section>

        <section className="py-16 bg-green-50">
          <div className="container mx-auto">
            <h2 className="text-3xl font-bold text-center mb-12 text-blue-900">Why Choose GS INSTITUT FILIPPO SMALDONE?</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[
                { icon: <Users size={40} />, title: 'Inclusive Education', description: 'We cater to both deaf and hearing students, fostering an inclusive learning environment.' },
                { icon: <GraduationCap size={40} />, title: 'Quality Education', description: 'As a government-aided school, we offer high-quality education at affordable rates.' },
                { icon: <Heart size={40} />, title: 'Catholic Values', description: 'Owned by The Sisters of the Sacred Hearts, we instill strong moral and ethical values.' },
                { icon: <Globe size={40} />, title: 'Cultural Diversity', description: 'Our diverse community prepares students to be global citizens.' },
                { icon: <Award size={40} />, title: 'Expert Faculty', description: 'Our teachers are trained to support both deaf and hearing students effectively.' },
                { icon: <Users size={40} />, title: 'Supportive Community', description: 'We foster a close-knit community where every student feels valued and supported.' }
              ].map((item, index) => (
                <Card key={index} className="text-center hover:shadow-lg transition-shadow">
                  <CardContent>
                    <div className="text-green-600 mb-4">{item.icon}</div>
                    <Typography variant="h6" component="div" sx={{ color: '#1e3a8a', mb: 2 }}>
                      {item.title}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#4b5563' }}>
                      {item.description}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </section>

        <section className="py-16">
          <div className="container mx-auto text-center">
            <h2 className="text-3xl font-bold mb-8 text-blue-900">Join Our Inclusive Community</h2>
            <p className="text-lg mb-8 text-gray-700 max-w-2xl mx-auto">
              Whether you're a prospective student, parent, or community member, we invite you to 
              become part of the GS INSTITUT FILIPPO SMALDONE family. Together, we can build a 
              brighter, more inclusive future for all our students.
            </p>
            <Button 
              variant="contained" 
              sx={{ 
                bgcolor: '#1e3a8a', 
                '&:hover': { bgcolor: '#1e40af' },
                color: 'white'
              }}
            >
              Contact Us
            </Button>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default About;