import React from 'react';
import Navbar from './Navbar';
import { AppBar, Toolbar, Typography, Button, Card, CardContent } from '@mui/material';
import { Menu, ChevronRight, GraduationCap, Users, Calendar, Library } from 'lucide-react';

const Footer = () => {
  return (
    <div>
    <footer className="bg-blue-900 text-white py-8">
        <div className="container mx-auto text-center">
          <Typography variant="body2">
            © 2024 GS INSTITUT FILIPPO SMALDONE. All rights reserved.
          </Typography>
        </div>
      </footer>
    </div>
  );
};

export default Footer;