import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Button, 
  IconButton, 
  Drawer, 
  List, 
  ListItem, 
  ListItemText, 
  ListItemIcon,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Menu, ChevronRight, GraduationCap, Users, Calendar, Library, Home, Info, Phone,User,FileText  } from 'lucide-react';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const isAuthenticated = !!localStorage.getItem('token');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleLogout = () => {
    localStorage.clear();
    navigate('/login');
    window.location.reload();
  };

  const handleNavClick = (path) => {
    navigate(path);
    setIsMenuOpen(false);
    if (isAuthenticated) {
      window.location.reload();
    }
  };

  const menuItems = [
    { text: 'Home', path: '/', icon: <Home /> },
    { text: 'Sign Language', path: '/signLanguage', icon: <GraduationCap /> },
    { text: 'About', path: '/about', icon: <Info /> },
    { text: 'Programs', path: '/programs', icon: <Calendar /> },
    { text: 'Contact', path: '/contact', icon: <Phone /> },
    { text: 'Blog', path: '/blog', icon: <FileText /> },
    { text: 'Admin', path: '/login', icon: <User /> },
  ];
 

  const renderMenuItems = () => (
    <List>
      {menuItems.map((item) => (
        <ListItem button key={item.text} onClick={() => handleNavClick(item.path)}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.text} />
        </ListItem>
      ))}
    </List>
  );

  return (
    <div>
      <AppBar position="static" sx={{ bgcolor: '#1e3a8a' }}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            GS INSTITUT FILIPPO SMALDONE
          </Typography>
          {isMobile ? (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setIsMenuOpen(true)}
            >
              <Menu />
            </IconButton>
          ) : (
            <div className="flex space-x-4">
              {menuItems.map((item) => (
                <Link key={item.text} to={item.path} className="text-white hover:text-yellow-300">
                  <Button color="inherit">{item.text}</Button>
                </Link>
              ))}
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="right"
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
      >
        {renderMenuItems()}
      </Drawer>
    </div>
  );
};

export default Navbar;