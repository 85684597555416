import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  Container, 
  Grid, 
  Card, 
  CardContent, 
  Typography, 
  TextField, 
  CircularProgress,
  Box,
  InputAdornment
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const SignLanguage = () => {
  const [signLanguages, setSignLanguages] = useState([]);
  const [filteredSignLanguages, setFilteredSignLanguages] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSignLanguages = async () => {
      try {
        const response = await axios.get('https://amarenga-appa.onrender.com/amarenga');
        const signData = response.data.map(lang => ({
          ...lang,
          gifLoaded: false,
          gifError: false
        }));
        setSignLanguages(signData);
        setFilteredSignLanguages(signData);
        setLoading(false);

        // Load GIFs asynchronously
        signData.forEach((lang, index) => {
          if (lang.avatar) {
            fetchGif(lang.avatar, index);
          }
        });
      } catch (err) {
        setError('Failed to fetch sign languages');
        setLoading(false);
      }
    };

    fetchSignLanguages();
  }, []);

  const fetchGif = async (gifUrl, index) => {
    try {
      const gifResponse = await axios.get(gifUrl, { responseType: 'blob' });
      const objectUrl = URL.createObjectURL(gifResponse.data);
      setSignLanguages(prevState => {
        const updatedLanguages = [...prevState];
        updatedLanguages[index] = { ...updatedLanguages[index], gifUrl: objectUrl, gifLoaded: true };
        return updatedLanguages;
      });
    } catch (error) {
      console.error(`Failed to load GIF for index ${index}:`, error);
      setSignLanguages(prevState => {
        const updatedLanguages = [...prevState];
        updatedLanguages[index] = { ...updatedLanguages[index], gifError: true };
        return updatedLanguages;
      });
    }
  };

  useEffect(() => {
    const results = signLanguages.filter(lang =>
      lang.kinyarwanda.toLowerCase().includes(searchTerm.toLowerCase()) ||
      lang.english.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredSignLanguages(results);
  }, [searchTerm, signLanguages]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const getImageUrl = (lang) => {
    if (lang.gifLoaded && lang.gifUrl) {
      return lang.gifUrl;
    } else if (lang.gif && lang.gif.startsWith('http')) {
      return lang.gif;
    } else {
      return 'path/to/placeholder-image.jpg';
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Navbar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4, flexGrow: 1 }}>
        <TextField
          fullWidth
          label="Search sign language"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
          sx={{ mb: 4 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        {loading ? (
          <CircularProgress sx={{ display: 'block', margin: 'auto' }} />
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          <Grid container spacing={3}>
            {filteredSignLanguages.map((lang, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ position: 'relative', paddingTop: '100%', overflow: 'hidden', mb: 2 }}>
                      {lang.gifError ? (
                        <Typography variant="body2" color="error" sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}>
                          GIF not available
                        </Typography>
                      ) : (
                        <img 
                          src={getImageUrl(lang)}
                          alt={lang.english} 
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                          }}
                        />
                      )}
                    </Box>
                    <Typography variant="h6">{lang.english}</Typography>
                    <Typography variant="subtitle1">{lang.kinyarwanda}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
      <Footer />
    </Box>
  );
};

export default SignLanguage;