import React from 'react';
import { Typography, Button, Card, CardContent, CardMedia } from '@mui/material';
import { ChevronRight, BookOpen, Users, Briefcase, Scissors } from 'lucide-react';
import { Helmet } from 'react-helmet';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const ProgramCard = ({ title, description, icon, imageSrc }) => (
  <Card className="h-full hover:shadow-lg transition-shadow">
    <CardMedia
      component="img"
      height="140"
      image={imageSrc}
      alt={`${title} program`}
    />
    <CardContent>
      <div className="text-blue-600 mb-4">{icon}</div>
      <Typography variant="h6" component="div" sx={{ color: '#1e3a8a', mb: 2 }}>
        {title}
      </Typography>
      <Typography variant="body2" sx={{ color: '#4b5563' }}>
        {description}
      </Typography>
    </CardContent>
  </Card>
);

const Programs = () => {
  const programs = [
    {
      title: "Nursery School",
      description: "A nurturing environment for our youngest learners, focusing on early childhood development and foundational skills for both deaf and hearing children.",
      icon: <Users size={40} />,
      imageSrc: "/images/IMG_3398.JPG"
    },
    {
      title: "Primary School",
      description: "Building a strong academic foundation with a comprehensive curriculum covering essential subjects, adapted for both deaf and hearing students.",
      icon: <BookOpen size={40} />,
     imageSrc: "/images/IMG_3398.JPG"
    },
    {
      title: "Secondary O-Level",
      description: "Preparing students for higher education with a rigorous academic program and diverse extracurricular activities, tailored for both deaf and hearing students.",
      icon: <Briefcase size={40} />,
    imageSrc: "/images/IMG_3398.JPG"
    },
    {
      title: "TVET Tailoring",
      description: "Vocational training in tailoring, equipping students with practical skills for a career in fashion and textiles, accessible to both deaf and hearing students.",
      icon: <Scissors size={40} />,
      imageSrc: "/images/IMG_3398.JPG"
    }
  ];

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <Helmet>
        <title>Programs at GS IFS - Inclusive Education for Deaf Students</title>
        <meta
          name="description"
          content="Explore the diverse programs offered at GS IFS, including nursery, secondary, and TVET, dedicated to providing inclusive education for deaf students."
        />
        <meta
          name="keywords"
          content="inclusive education, nursery, secondary, TVET, deaf school, GS IFS, programs for deaf students, best school Rwanda"
        />
      </Helmet>
      <Navbar />

      <main className="flex-grow">
        <section className="bg-blue-800 text-white py-20">
          <div className="container mx-auto text-center">
            <h1 className="text-4xl md:text-6xl font-bold mb-4">Our Programs</h1>
            <p className="text-xl mb-8">Empowering Minds at Every Stage - For Deaf and Hearing Students</p>
          </div>
        </section>

        <section className="py-16">
          <div className="container mx-auto">
            <h2 className="text-3xl font-bold text-center mb-12 text-blue-900">Educational Pathways at GS INSTITUT FILIPPO SMALDONE</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {programs.map((program, index) => (
                <ProgramCard key={index} {...program} />
              ))}
            </div>
          </div>
        </section>

        <section className="py-16 bg-yellow-50">
          <div className="container mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
              <div>
                <h2 className="text-3xl font-bold mb-4 text-blue-900">Our Approach to Inclusive Learning</h2>
                <p className="text-lg mb-6 text-gray-700">
                  At GS INSTITUT FILIPPO SMALDONE, we believe in a holistic approach to education that 
                  caters to both deaf and hearing students. Our programs are designed to nurture not just 
                  academic excellence, but also personal growth, creativity, and practical skills. 
                </p>
                <p className="text-lg mb-6 text-gray-700">
                  From our playful nursery classes to our career-focused TVET programs, we're committed 
                  to helping every student shine, regardless of their hearing ability. Our curriculum is 
                  adapted to ensure that both deaf and hearing students can learn effectively side by side.
                </p>
                <Button 
                  variant="contained" 
                  sx={{ 
                    bgcolor: '#eab308', 
                    '&:hover': { bgcolor: '#ca8a04' }, 
                    color: '#1e3a8a'
                  }}
                >
                  Explore Programs <ChevronRight className="ml-2" />
                </Button>
              </div>
              <div className="bg-yellow-400 h-64 rounded-lg shadow-lg"></div>
            </div>
          </div>
        </section>

        <section className="py-16">
          <div className="container mx-auto text-center">
            <h2 className="text-3xl font-bold mb-8 text-blue-900">Start Your Inclusive Educational Journey with Us</h2>
            <p className="text-lg mb-8 text-gray-700 max-w-2xl mx-auto">
              Whether you're looking for early childhood education, primary schooling, secondary 
              studies, or vocational training, GS INSTITUT FILIPPO SMALDONE has a program to suit your needs. 
              Join us and embark on a bright educational journey in an inclusive, supportive environment.
            </p>
            <Button 
              variant="contained" 
              sx={{ 
                bgcolor: '#1e3a8a', 
                '&:hover': { bgcolor: '#1e40af' },
                color: 'white'
              }}
            >
              Apply Now
            </Button>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default Programs;