import React, { useState } from 'react';
import { Typography, Button, TextField, Card, CardContent } from '@mui/material';
import { Phone, Mail, MapPin, Send } from 'lucide-react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const ContactInfo = ({ icon, title, content }) => (
  <div className="flex items-center mb-4">
    <div className="text-blue-600 mr-4">{icon}</div>
    <div>
      <Typography variant="h6" className="text-blue-900">{title}</Typography>
      <Typography variant="body1" className="text-gray-700">{content}</Typography>
    </div>
  </div>
);

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the form data to your backend
    console.log('Form submitted:', formData);
    // Reset form after submission
    setFormData({ name: '', email: '', message: '' });
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <Helmet>
  <title>Contact GS IFS - Reach Out</title>
  <meta name="description" content="Get in touch with GS IFS for inquiries about deaf education and our programs." />
  <meta name="keywords" content="contact GS IFS, deaf school Rwanda, inquiries, education services" />
</Helmet>

      <Navbar />

      <main className="flex-grow">
        <section className="bg-blue-800 text-white py-20">
          <div className="container mx-auto text-center">
            <h1 className="text-4xl md:text-6xl font-bold mb-4">Contact Us</h1>
            <p className="text-xl mb-8">Get in touch with GS INSTITUT FILIPPO SMALDONE</p>
          </div>
        </section>

        <section className="py-16">
          <div className="container mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <Card className="p-6">
                <CardContent>
                  <Typography variant="h5" className="mb-6 text-blue-900">Our Contact Information</Typography>
                  <ContactInfo 
                    icon={<MapPin size={24} />} 
                    title="Address" 
                    content="Kigali, Nyamirambo, Rwanda" 
                  />
                  <ContactInfo 
                    icon={<Phone size={24} />} 
                    title="Phone" 
                    content="+250787703798" 
                  />
                  <ContactInfo 
                    icon={<Mail size={24} />} 
                    title="Email" 
                    content="info@filipposmaldone.fr" 
                  />
                </CardContent>
              </Card>

              <Card className="p-6">
                <CardContent>
                  <Typography variant="h5" className="mb-6 text-blue-900">Send Us a Message</Typography>
                  <form onSubmit={handleSubmit}>
                    <TextField
                      fullWidth
                      label="Name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      margin="normal"
                      required
                    />
                    <TextField
                      fullWidth
                      label="Email"
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleChange}
                      margin="normal"
                      required
                    />
                    <TextField
                      fullWidth
                      label="Message"
                      name="message"
                      multiline
                      rows={4}
                      value={formData.message}
                      onChange={handleChange}
                      margin="normal"
                      required
                    />
                    <Button 
                      type="submit" 
                      variant="contained" 
                      sx={{ 
                        mt: 2,
                        bgcolor: '#1e3a8a', 
                        '&:hover': { bgcolor: '#1e40af' },
                        color: 'white'
                      }}
                      startIcon={<Send />}
                    >
                      Send Message
                    </Button>
                  </form>
                </CardContent>
              </Card>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default Contact;