import React, { useState, useEffect, useCallback } from 'react';
import { Typography, Button, Card, CardContent, CardMedia, IconButton, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { X as CloseIcon } from 'lucide-react';
import { Document, Page, pdfjs } from 'react-pdf';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

// Set up PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const API_URL = process.env.REACT_APP_API_URL;

const PublicationCard = ({ id, title, description, image, pdf, onViewFull, isExpanded }) => (
  <Card className={`mb-6 ${isExpanded ? 'col-span-full' : ''}`}>
    {image && (
      <CardMedia
        component="img"
        height="200"
        image={image}
        alt={title}
      />
    )}
    <CardContent>
      <Typography variant="h5" component="div" className="mb-2">
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary" className="mb-4">
        {isExpanded ? description : `${description.substring(0, 100)}...`}
      </Typography>
      <Button onClick={() => onViewFull(id)} variant="outlined" color="primary">
        View Full
      </Button>
    </CardContent>
  </Card>
);

const DocumentViewer = ({ url }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  if (url.endsWith('.pdf')) {
    return (
      <div>
        <Document
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(error) => console.error('Error loading PDF:', error)}
        >
          <Page pageNumber={pageNumber} />
        </Document>
        <p>
          Page {pageNumber} of {numPages}
        </p>
        <Button 
          disabled={pageNumber <= 1} 
          onClick={() => setPageNumber(pageNumber - 1)}
        >
          Previous
        </Button>
        <Button 
          disabled={pageNumber >= numPages} 
          onClick={() => setPageNumber(pageNumber + 1)}
        >
          Next
        </Button>
      </div>
    );
  } else {
    return <img src={url} alt="Publication content" style={{ maxWidth: '100%', maxHeight: '80vh' }} />;
  }
};

const Blog = () => {
  const [publications, setPublications] = useState([]);
  const [selectedPublication, setSelectedPublication] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const fetchPublications = useCallback(async () => {
    try {
      const response = await fetch(`${API_URL}/publication`);
      if (!response.ok) {
        throw new Error('Failed to fetch publications');
      }
      const data = await response.json();
      setPublications(data);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchPublications();
  }, [fetchPublications]);

  const handleViewFull = useCallback(async (id) => {
    try {
      const response = await fetch(`${API_URL}/publication/${id}`);
      if (!response.ok) {
        throw new Error('Failed to fetch publication details');
      }
      const data = await response.json();
      setSelectedPublication(data);
      setOpenDialog(true);
    } catch (err) {
      setError(err.message);
    }
  }, []);

  const handleCloseDialog = useCallback(() => {
    setOpenDialog(false);
    setSelectedPublication(null);
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <Navbar />
      
      <main className="flex-grow container mx-auto py-8">
        <Typography variant="h2" component="h1" className="text-3xl font-bold mb-8 text-center">
          Our Publications
        </Typography>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {publications.map(publication => (
            <PublicationCard
              key={publication._id}
              id={publication._id}
              title={publication.title}
              description={publication.description}
              image={publication.image}
              pdf={publication.pdf}
              onViewFull={handleViewFull(publication._id)}
              isExpanded={false}
            />
          ))}
        </div>

        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>
            {selectedPublication?.title}
            <IconButton
              aria-label="close"
              onClick={handleCloseDialog}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {selectedPublication && (
              <DocumentViewer 
                url={selectedPublication.pdf || selectedPublication.image} 
              />
            )}
          </DialogContent>
        </Dialog>
      </main>
      
      <Footer />
    </div>
  );
};

export default Blog;