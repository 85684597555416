import React, { useState, useEffect } from 'react';
import { Typography, Button } from '@mui/material';
import { ChevronRight, GraduationCap, Users, Calendar, Heart } from 'lucide-react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';


const slideData = [
  {
    image: "/images/graduaion_3.JPG",
    article: "Celebrating the school's graduation ceremonies.",
  },
  {
    image: "/images/carousel-1.jpg",
    article: "Discover inclusive education opportunities at GS Institut Filippo Smaldone.",
  },
  {
    image: "/images/carousel-2.jpg",
    article: "Our facilities provide equal support for both deaf and hearing students.",
  },
  {
    image: "/images/carousel-3.jpg",
    article: "Join a dynamic community of enthusiastic learners and dedicated educators.",
  },
  {
    image: "/images/IMG_7434.jpg",
    article: "Get hands-on experience through practical field training.",
  },
  {
    image: "/images/IMG_20230426_143720_085.jpg",
    article: "Enhance your digital skills with our ICT lessons.",
  },
  {
    image: "/images/IMG-20230214-WA0003.jpg",
    article: "Immerse yourself in our vibrant and diverse cultural setting.",
  },
  {
    image: "/images/huye_museum.jpg",
    article: "Engage in enriching museum visits and cultural experiences.",
  },
  {
    image: "/images/Graduation_day.JPG",
    article: "Memorable moments from the school's graduation day.",
  },
  {
    image: "/images/visiting_dam.jpg",
    article: "Enhancing science learning through hands-on field trips.",
  },
];



const Slideshow = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slideData.length);
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="relative h-48 sm:h-64 md:h-80 lg:h-96 overflow-hidden">
    <Helmet>
  <title>GS IFS - Deaf School in Rwanda</title>
  <meta name="description" content="Welcome to GS IFS, the best school for deaf education in Rwanda, offering inclusive education and diverse programs." />
  <meta name="keywords" content="deaf school, GS IFS, best school Rwanda, inclusive education, Rwandan schools" />
</Helmet>

      {slideData.map((slide, index) => (
        <div
          key={index}
          className={`absolute top-0 left-0 w-full h-full transition-opacity duration-1000 ${
            index === currentSlide ? 'opacity-100' : 'opacity-0'
          }`}
          style={{
            backgroundImage: `url(${slide.image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-2 sm:p-4">
            <Typography variant="h6" className="text-sm sm:text-base md:text-lg lg:text-xl">
              {slide.article}
            </Typography>
          </div>
        </div>
      ))}
    </div>
  );
};

const Home = () => {
  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <Navbar />

      <main className="flex-grow">
        <Slideshow />

        <section className="py-8 sm:py-12 md:py-16">
          <div className="container mx-auto px-4">
            <div className="text-center">
              <Typography variant="h4" className="text-2xl sm:text-3xl md:text-4xl font-bold mb-2 sm:mb-4 text-blue-900">
                Welcome to GS INSTITUT FILIPPO SMALDONE
              </Typography>
              <Typography variant="h5" className="text-base sm:text-lg md:text-xl mb-4 sm:mb-6 md:mb-8 text-gray-700">
                Empowering Minds, Shaping Futures - An Inclusive School for Deaf and Hearing Students
              </Typography>
              <Button 
                variant="contained" 
                sx={{ 
                  bgcolor: '#eab308', 
                  '&:hover': { bgcolor: '#ca8a04' }, 
                  color: '#1e3a8a'
                }}
              >
                Learn More <ChevronRight className="ml-2" />
              </Button>
            </div>
          </div>
        </section>

        <section className="py-8 sm:py-12 md:py-16 bg-blue-50">
          <div className="container mx-auto px-4">
            <Typography variant="h3" className="text-xl sm:text-2xl md:text-3xl font-bold text-center mb-6 sm:mb-8 md:mb-12 text-blue-900">
              Why Choose Us?
            </Typography>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6 md:gap-8">
              {[
                { icon: <GraduationCap size={32} />, title: 'Inclusive Education' },
                { icon: <Users size={32} />, title: 'Expert Faculty' },
                { icon: <Calendar size={32} />, title: 'Government Aided' },
                { icon: <Heart size={32} />, title: 'Catholic Values' }
              ].map((item, index) => (
                <div key={index} className="bg-white rounded-lg shadow-md p-4 text-center hover:shadow-lg transition-shadow">
                  <div className="text-blue-600 mb-2 sm:mb-4 flex justify-center">{item.icon}</div>
                  <Typography variant="h6" component="div" className="text-sm sm:text-base md:text-lg text-blue-900">
                    {item.title}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="py-8 sm:py-12 md:py-16 bg-yellow-50">
          <div className="container mx-auto px-4 text-center">
            <Typography variant="h3" className="text-xl sm:text-2xl md:text-3xl font-bold mb-4 sm:mb-6 md:mb-8 text-blue-900">
              Ready to Join Us?
            </Typography>
            <Typography variant="body1" className="mb-4 sm:mb-6 md:mb-8 text-gray-700 max-w-2xl mx-auto text-sm sm:text-base">
              Experience inclusive education in the heart of Kigali, Nyamirambo, Rwanda. 
              GS INSTITUT FILIPPO SMALDONE, owned by The Sisters of the Sacred Hearts, 
              welcomes both deaf and hearing students.
            </Typography>
            <Button 
              variant="contained" 
              sx={{ 
                bgcolor: '#1e3a8a', 
                '&:hover': { bgcolor: '#1e40af' },
                color: 'white'
              }}
            >
              Apply Now
            </Button>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default Home;