import React, { useState, useEffect } from 'react';
import { 
  AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemIcon, ListItemText,
  Typography, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, Dialog, DialogTitle, DialogContent, DialogActions, TextField,
  CircularProgress, Snackbar, Alert
} from '@mui/material';
import {
  Menu as MenuIcon, Dashboard as DashboardIcon, Book as BookIcon, 
  Announcement as AnnouncementIcon, VideoLibrary as VideoIcon, ExitToApp as LogoutIcon,
  Person as PersonIcon, Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon
} from '@mui/icons-material';

const API_URL = process.env.REACT_APP_API_URL;

const UserDashboard = () => {
  const [open, setOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('dashboard');
  const [publications, setPublications] = useState([]);
  const [announcements, setAnnouncements] = useState([]);
  const [amarenga, setAmarenga] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [formData, setFormData] = useState({});
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, [activeSection]);

  const fetchData = async () => {
    setLoading(true);
    try {
      let data;
      switch (activeSection) {
        case 'publications':
          data = await fetchFromAPI('/publication');
          setPublications(data);
          break;
        case 'announcements':
          data = await fetchFromAPI('/announcement');
          setAnnouncements(data);
          break;
        case 'amarenga':
          data = await fetchFromAPI('/amarenga');
          setAmarenga(data);
          break;
        default:
          break;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      showSnackbar('Error fetching data', 'error');
    } finally {
      setLoading(false);
    }
  };

  const fetchFromAPI = async (endpoint) => {
    const response = await fetch(`${API_URL}${endpoint}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleSectionChange = (section) => {
    setActiveSection(section);
    setOpen(false);
  };

  const handleDialogOpen = (type) => {
    setDialogType(type);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setFormData({});
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formDataWithFile = new FormData();
      Object.keys(formData).forEach(key => formDataWithFile.append(key, formData[key]));

      const response = await fetch(`${API_URL}/${dialogType}`, {
        method: 'POST',
        body: formDataWithFile,
      });

      if (response.ok) {
        showSnackbar(`${dialogType} created successfully`, 'success');
        fetchData();
        handleDialogClose();
      } else {
        throw new Error('Failed to create');
      }
    } catch (error) {
      console.error('Error creating:', error);
      showSnackbar(`Error creating ${dialogType}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (type, id) => {
    setLoading(true);
    try {
      const response = await fetch(`${API_URL}/${type}/delete/${id}`, {
        method: 'DELETE',
      });
      console.log(type)

      if (response.ok) {
        showSnackbar(`${type} deleted successfully`, 'success');
        fetchData();
      } else {
        throw new Error('Failed to delete');
      }
    } catch (error) {
      console.error('Error deleting:', error);
      showSnackbar(`Error deleting ${type}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };
 const  handleLogout=()=> {
   localStorage.removeItem('token');
   window.location.href='/login';
 }

  const renderTable = (data, type) => (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {Object.keys(data[0] || {}).filter(key => !['_id', 'cloudinary_id'].includes(key)).map((key) => (
              <TableCell key={key}>{key}</TableCell>
            ))}
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => (
            <TableRow key={item._id}>
              {Object.entries(item).filter(([key]) => !['_id', 'cloudinary_id'].includes(key)).map(([key, value]) => (
                <TableCell key={key}>
                  {key === 'avatar' ? (
                    <img src={value} alt={item.kinyarwanda || item.title} style={{ width: '50px', height: '50px' }} />
                  ) : (
                    value
                  )}
                </TableCell>
              ))}
              <TableCell>
                <IconButton onClick={() => handleDelete(type, item._id)}>
                  <DeleteIcon />
                </IconButton>
                <IconButton onClick={() => handleDialogOpen(type)}>
                  <EditIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <div style={{ display: 'flex' }}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap style={{ flexGrow: 1 }}>
            Admin Dashboard
          </Typography>
          <IconButton color="inherit">
            <PersonIcon />
          </IconButton>
          <Typography variant="body1" style={{ marginRight: '1rem' }}>
            admin
          </Typography>
          <Button color="inherit" startIcon={<LogoutIcon />}
           onClick={() => handleLogout()}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        open={open}
        onClose={handleDrawerToggle}
      >
        <List>
          {['Dashboard', 'Publications', 'Announcements', 'Amarenga'].map((text) => (
            <ListItem button key={text} onClick={() => handleSectionChange(text.toLowerCase())}>
              <ListItemIcon>
                {text === 'Dashboard' && <DashboardIcon />}
                {text === 'Publications' && <BookIcon />}
                {text === 'Announcements' && <AnnouncementIcon />}
                {text === 'Amarenga' && <VideoIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <main style={{ flexGrow: 1, padding: '2rem', marginTop: '64px' }}>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {activeSection === 'dashboard' && (
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Paper style={{ padding: '1rem' }}>
                    <Typography variant="h6">Total Publications</Typography>
                    <Typography variant="h4">{publications.length}</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper style={{ padding: '1rem' }}>
                    <Typography variant="h6">Total Announcements</Typography>
                    <Typography variant="h4">{announcements.length}</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper style={{ padding: '1rem' }}>
                    <Typography variant="h6">Total Amarenga Videos</Typography>
                    <Typography variant="h4">{amarenga.length}</Typography>
                  </Paper>
                </Grid>
              </Grid>
            )}
            {activeSection === 'publications' && (
              <>
                <Button 
                  variant="contained" 
                  startIcon={<AddIcon />} 
                  onClick={() => handleDialogOpen('publication')}
                  style={{ marginBottom: '1rem' }}
                >
                  Add Publication
                </Button>
                {renderTable(publications, 'publication')}
              </>
            )}
            {activeSection === 'announcements' && (
              <>
                <Button 
                  variant="contained" 
                  startIcon={<AddIcon />} 
                  onClick={() => handleDialogOpen('announcement')}
                  style={{ marginBottom: '1rem' }}
                >
                  Add Announcement
                </Button>
                {renderTable(announcements, 'announcement')}
              </>
            )}
            {activeSection === 'amarenga' && (
              <>
                <Button 
                  variant="contained" 
                  startIcon={<AddIcon />} 
                  onClick={() => handleDialogOpen('amarenga')}
                  style={{ marginBottom: '1rem' }}
                >
                  Add Amarenga
                </Button>
                {renderTable(amarenga, 'amarenga')}
              </>
            )}
          </>
        )}
      </main>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>{`Add ${dialogType}`}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            {dialogType === 'publication' && (
              <>
                <TextField name="title" label="Title" fullWidth margin="normal" onChange={handleInputChange} />
                <TextField name="description" label="Description" fullWidth margin="normal" onChange={handleInputChange} />
                <input type="file" name="pdf" onChange={handleFileChange} />
              </>
            )}
            {dialogType === 'announcement' && (
              <>
                <TextField name="title" label="Title" fullWidth margin="normal" onChange={handleInputChange} />
                <TextField name="content" label="Content" fullWidth margin="normal" onChange={handleInputChange} />
                <input type="file" name="file" onChange={handleFileChange} />
              </>
            )}
            {dialogType === 'amarenga' && (
              <>
                <TextField name="kinyarwanda" label="Kinyarwanda" fullWidth margin="normal" onChange={handleInputChange} />
                <TextField name="english" label="English" fullWidth margin="normal" onChange={handleInputChange} />
                <TextField name="french" label="French" fullWidth margin="normal" onChange={handleInputChange} />
                <input type="file" name="image" onChange={handleFileChange} />
              </>
            )}
            <DialogActions>
              <Button onClick={handleDialogClose}>Cancel</Button>
              <Button type="submit" variant="contained" color="primary">Submit</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default UserDashboard;